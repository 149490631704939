import React, {useEffect, useState} from 'react';
import {BoardVO} from "../../../core/board/board.vo";
import StringUtils from "../../../common/utils/string.utils";
import MainService from "../../../core/main/main.service";
import elandUtils from "../../../common/utils/eland.utils";

interface MainUseStoryProps {
}

/*
    지식콘텐츠 - 적용스토리에 등록된 베스트 게시글을 최신 순으로 가져옴

    콘텐츠시청 또는 사내고수 문의 완료 후 작성하게 됨
    단순 댓글과는 다르며, 몇 가지 질문이 있음

    따라서 후기의 구성요소는
    지식콘텐츠 후기 / 사내고수 후기
    무엇에 대한 후기인지(지식콘텐츠명, 사내고수이름)
    작성자
    작성일
    후기 제목
    후기 내용
    사진 / 파일을 올릴 수도 있음, 사진 및 파일은 상세에서만 노출
*/
const MainUseStory = (props: MainUseStoryProps) => {
    const [board, setBoard] = useState<BoardVO | undefined>(undefined)
    const [pro, setPro] = useState<BoardVO | undefined>(undefined)


    useEffect(() => {
        getMainUseStory()

    }, []);

    const getMainUseStory = () => {
        const params = {
            sort: "createDate,DESC"
        };

        const successHandler = async (result: any) => {
            const board = result.data.board;
            const pro = result.data.pro;
            await renderJobCode(pro)

            setBoard(board);
            setPro(pro);
        }

        MainService.getMainUseStory(params, successHandler)
    }

    const renderJobCode = async (pro: BoardVO) => {
        if (pro && pro.receiverProInfo && pro.receiverProInfo.companyAccountVO) {
            let proInfo = pro.receiverProInfo
            let jobName = await elandUtils.getJobSkillCodeName(proInfo.jobCode);
            pro.receiverProInfo.jobCodeName = jobName;
        }
    }

    const getProInfoMsg = () => {
        if (pro && pro.receiverProInfo && pro.receiverProInfo.companyAccountVO) {
            let proInfo = pro.receiverProInfo
            let company = proInfo.companyAccountVO?.coInfoVO?.coName ?? ""
            let userName = proInfo.companyAccountVO?.userName ?? ""
            let jobName = proInfo.jobCodeName
            let strengthKeyword = proInfo.officialKeyword.split(',')
                .map(item => '#' + item)
                .join(',')

            return (`${company} ${userName} - ${jobName} ${strengthKeyword}`);
        }
        return  ''
    }

    return (
        <>
            {
                (board == null && pro == null) ? <></> : <section className="apply-section">
                    <div className="inner">
                        <div className="section-title">
                            <h2 className="tit">적용스토리</h2>
                            <a href="/board/story/use_story" className="link-more">더보기</a>
                        </div>
                        <ul className="story-list">
                            {
                                (board == null) ? <></> : <li className="knowledge">
                                    <a href={
                                        "/board/story/use_story/" + board?.idx + "/" + board?.title
                                    }>
                                        { board.cateCode === `040501` && <span className="badge type1 orange">지식콘텐츠</span>}
                                        { board.cateCode === `040504` && <span className="badge type1 orange">코스학습</span>}
                                        <strong className="tit">{board?.title}</strong>
                                        <div className="info-wrap">
                                            <span className="writer">{StringUtils.maksedName(board?.writer)}</span>
                                            <span className="date">{StringUtils.formatDate(board?.createDate)}</span>
                                        </div>
                                        <p className="desc">{board?.contentText}</p>
                                    </a>
                                    { board.cateCode === `040501` &&
                                        <a href={"/board/story/use_story/" + board?.idx + "/" + board?.title}>
                                            <p className="review">
                                                <span>Best 후기</span>
                                                <strong>
                                                    {board?.bestReviewTitle}
                                                </strong>
                                            </p>
                                        </a>
                                    }
                                    { board.cateCode === `040504` &&
                                        <a href={`javascript:;`}>
                                            <p className="review">
                                                <span>Best 후기</span>
                                                <strong>
                                                    {board?.bestReviewTitle}
                                                </strong>
                                            </p>
                                        </a>
                                    }
                                </li>
                            }
                            {
                                (pro == null) ? <></> : <li className="master">
                                    <a href={
                                        "/board/story/use_story/" + pro?.idx + "/" + pro?.title
                                    }>
                                        <span className="badge type1 red">사내고수</span>
                                        <strong className="tit">{pro?.title}</strong>
                                        <div className="info-wrap">
                                            <span className="writer">{StringUtils.maksedName(pro?.writer)}</span>
                                            <span className="date">{StringUtils.formatDate(pro?.createDate)}</span>
                                        </div>
                                        <p className="desc">{pro?.contentText}</p>
                                    </a>
                                    <a href={
                                        "/board/story/use_story/" + pro?.idx + "/" + pro?.title
                                    }>
                                        <p className="review">
                                            <span>Best 후기</span>
                                            <strong>
                                                {getProInfoMsg()}
                                            </strong>
                                        </p>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </section>
            }
        </>
    )
}

export default MainUseStory;