import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import M_BoardMov from "../M_BoardMov";
import M_BoardCard from "../M_BoardCard";
import APIs from "../../../../common/apis/APIs";
import {BoardFileVO, BoardVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import ToastUtils from "../../../../common/utils/toast.utils";
import StringUtils from "../../../../common/utils/string.utils";
import M_SummernoteIframe from "../../common/M_SummernoteIframe";
import {HWMap} from "../../../../core/common/common.vo";

interface MovCardDetailProps {
    boardInfoVO: BoardInfoVO,
    boardVO: BoardVO,
    fileList: BoardFileVO[],
    tags: string[],
}

const M_MovCardDetail: React.FC<MovCardDetailProps> = ({boardInfoVO, boardVO, fileList: initialFileList, tags}) => {
    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [isWish, setIsWish] = useState(false);

    // 탭 순서) 강의 설명 : 0, AI 요약 beta : 1, 스크립트 : 2
    const [currentTab, setCurrentTab] = useState(0);

    const [fileList, setFileList] = useState(initialFileList);
    const [fileInfo, setFileInfo] = useState<HWMap>({} as HWMap)

    useEffect(() => {
        if (boardVO) {
            setIsWish(boardVO.isWish)
        }

        // CommonJS.tabmenu()
    }, [boardVO]);
    
    
    
    useEffect(() => {
        
        /**
         * FileInfoVO 에서 script + summary 표출
         *
         */
        let aiHtml = "";
        let scriptHtml = "";
        if (initialFileList.length > 0 && initialFileList[0].fileInfoVO.seq > 0 ) {
            const fileInfo = initialFileList[0].fileInfoVO;
            
            if ( fileInfo.fileStatus === "AI_COMPLETE" ) {
                
                aiHtml = fileInfo.summary && fileInfo.summary.replaceAll(/\n/gi, "<br />");
                scriptHtml = fileInfo.description && fileInfo.description.replaceAll(/\n/gi, "<br />");
                
                // 기본안내문구 prepend
                aiHtml = "✨ELAND AI가 스크립트를 기반으로 핵심주제와 지식을 요약해드립니다.<br /><br />" + aiHtml
                scriptHtml = "✨ELAND AI가 영상 내 음성을 기반으로 스크립트를 정리해드려요.<br /><br />" + scriptHtml
            }
            
            if ( ["AI_DAGLO_PROCESS", "AI_GPT_PROCESS"].includes(fileInfo.fileStatus) ) {
                aiHtml = "⏳ AI가 콘텐츠 요약을 생성하고 있습니다. 잠시만 기다려 주세요!<br /><br />"
                scriptHtml = "⏳ AI가 스크립트를 생성하고 있습니다. 잠시만 기다려 주세요!<br /><br />"
            }
            
        } else {
            aiHtml = boardVO.aiHtml
            scriptHtml = boardVO.scriptHtml
        }
        
        setFileInfo({
            aiHtml: aiHtml,
            scriptHtml: scriptHtml,
        })
        
        setFileList(initialFileList)
        
    }, [initialFileList]);

    /**
     * 관심 지식
     */
    const setBookMark = () => {

        (async () => {
            let result;

            if (isWish) {
                result = await boardService.deleteWishBoard({bidx: bidx});
            } else {
                result = await boardService.putWishBoard({bidx: bidx});
            }

            if (result.status == "SUCCESS" && result.data) {
                setIsWish(result.data.isWished);
                if (isWish) {
                    ToastUtils.show("관심 지식에서 해제되었습니다.", 2)
                } else {
                    ToastUtils.show("관심 지식에 저장되었습니다.", 2)
                }
            }

        })()
    }

    return (
        <section className="detail-section type-media border-10">
            <div className="detail-title">
                <div className="inner">
                    <div className="cate">
                        <a href="#" style={{cursor: "default"}} onClick={(e) => {
                            e.preventDefault()
                        }}>{boardVO.jobName}&nbsp;</a>
                    </div>
                    <h2 className="tit">{boardVO.title}</h2>
                    <button type="button" className={`btn-bookmark ${isWish && 'active'}`} onClick={setBookMark}>
                        <span className="blind">북마크</span>
                    </button>
                    <div className="media-content">
                        {boardVO.boardType === "mov" && <M_BoardMov fileList={fileList} boardVO={boardVO}/>}
                        {boardVO.boardType === "card" && <M_BoardCard fileList={fileList} boardVO={boardVO}/>}
                        {boardVO.boardType === "pdf" && <M_BoardCard fileList={fileList} boardVO={boardVO}/>}
                    </div>
                </div>
            </div>
            <div className="detail-content">
                <div className="inner">
                    <div className="tabs">
                        <ul className="tab-menu">
                            <li className={`tab-item explain ${currentTab == 0 ? 'active' : ''}`}
                                style={{cursor: 'pointer'}} onClick={() => setCurrentTab(0)}>
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                }}>강의 설명</a>
                            </li>
                            {
                                !StringUtils.isEmpty(fileInfo.aiHtml) && (
                                    <li className={`tab-item ai ${currentTab == 1 ? 'active' : ''}`}
                                        style={{cursor: 'pointer'}} onClick={() => setCurrentTab(1)}>
                                        <a href="#" onClick={e => e.preventDefault()}>
                                            AI 요약 beta
                                        </a>
                                    </li>
                                )
                            }

                            {
                                !StringUtils.isEmpty(fileInfo.scriptHtml) && (
                                    <li className={`tab-item script ${currentTab == 2 ? 'active' : ''}`}
                                        style={{cursor: 'pointer'}} onClick={() => setCurrentTab(2)}>
                                        <a href="#" onClick={e => e.preventDefault()}>
                                            스크립트
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="tab-panel tab-explain">
                            {
                                currentTab == 0 &&
                                <>
                                    <ul className="attach-list">
                                        {
                                            boardVO.attachList?.map((file, index) => (
                                                file.fileMode === 'attach' && file.fidx != boardVO.thumbIdx &&
                                                <li key={index} className="attach">
                                                    <b className="tit">첨부파일 </b>
                                                    <a href={`${APIs.BOARD_FILES_DOWN}${file.fidx}`}>
                                                        <span className="path">{file.filename}</span>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className="text-area" style={{
                                        marginTop: '0px'
                                    }}>
                                        <M_SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                                    </div>
                                </>
                            }

                            {
                                currentTab == 1 &&
                                <div className="tab-panel tab-ai">
                                    <div className="text-area" dangerouslySetInnerHTML={{__html:fileInfo.aiHtml }}>
                                        {/*<M_SummernoteIframe mode={"view"} htmlCode={boardVO.aiHtml}/>*/}
                                    </div>
                                </div>
                            }
                            {
                                currentTab == 2 &&
                                <div className="tab-panel tab-script">
                                    <div className="text-area" dangerouslySetInnerHTML={{__html:fileInfo.scriptHtml }}>
                                        {/*<M_SummernoteIframe mode={"view"} htmlCode={boardVO.scriptHtml}/>*/}
                                    </div>
                                </div>
                            }


                            {
                                tags &&
                                <div className="tag-list">
                                    {tags?.map((tag, index) => (
                                        <React.Fragment key={index}>
                                            <Link to={`/search/${tag}`}>#{tag}</Link>
                                            {index < tags.length - 1 ? ', ' : ''}
                                        </React.Fragment>
                                    ))}
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default M_MovCardDetail;