import {CodeVO} from "../common/code.vo"
import {FileInfoVO, HWMap} from "../common/common.vo";
import {CompanyAccountVO} from "../company/company.vo";


export interface EduMainVO extends HWMap{

	emIdx: number
	eduTitle: string            //교육 명
	introduce: string           // 개요
	goal: string                // 목표

	scheduleMode :string
	startDate: string           // 교육 시작일
	endDate: string             // 교육 마감일

	isEnd: boolean				// 종료된 교육여부
	isStep: boolean             // 단계별 진형여주
	isMajor: boolean            // 주요교육 여부
	isDisplay: boolean          // 교육 공개 여부 ( 전체컨텐츠 등록 전엔 무조건 false + 컨텐츠 수정시 false 처리 )
	point: number               //교육 완료시 지급될 포인트
	memberLevel: number         //대상 회원 레벨벨
	displayNum: number          // 교육 표출용 번호 TODO : MAX 계산
	numLeft: number             // 선착순 제한인원수 TODO : 0일 경우 무제한
	cateCode: CodeVO            // 카테고리
	allStudents: boolean        // 전체 대상
	teachId: string             // 강사 ID (존재시)
	teachName: string           // 강사명
	teacherVO: CompanyAccountVO // 강사VO
	thumbnail?: string          // 강의 썸네일 url
	countConfig: number         // 구성 개수
	contentText: string         //교육 소개
	views: number               // 조회수
	contentHtml: string         //교육 소개 HTML
	isOpen: boolean             // 현재 교육 오픈 여부 : 오픈일자 + 등급?
	rateCode: number            // 공개 회원 등급

	studentAllCount: number
	studentCount: number
	requestCount: number
	completeCount: number
	contentCount: number
	answerCount: number
	likeCount: number
	userCount: number
	teamCount: number
	rateCount: number

	contentList: EduContentVO[]
	answerList: EduAnswerVO[]
	cateCodeVO: CodeVO
	studentList: []
	targetList: []            
	studentVO: {}
}




export interface EduContentVO extends HWMap{

	ecIdx :number
	emIdx :number
	contentType :string

	ordering :number
	eduMainVO :EduMainVO
	qstMovVO :QstMovVO
	qstFileVO :QstFileVO
	qstTstOptionVO :QstTstOptionVO
	qstTstList :QstTstVO[]

	qstCardVO :QstCardVO
	qstReviewVO :QstReviewVO
	qstPollOptionVO :QstPollOptionVO
	qstPollList :QstPollVO[]

	recordCount :number
	recordDate :string
	completeCount :number
}


export interface QstCardVO extends HWMap{
	ecIdx :number
	cardName :string
	cardDesc :string
	cardType :string
	fileList :CardFileVO
}



export interface QstFileVO extends HWMap{
	ecIdx :number
	fileTitle :string
	fileDesc :string
	fileList :EduFileVO[]
}




export interface QstMovVO extends HWMap{
	ecIdx :number
	movTitle :string
	movUrl :string
	movFidx :number
	movType :string
	hashParams :string
	movThumb :string
	isSpeed :boolean
	isSkip :boolean
	playMinutes :number
	playSeconds :number
	note :string
	aiSummary :string
	scriptText :string
	
	fileInfoVO: FileInfoVO
}
export interface QstMovOptionVO extends HWMap{
	ecIdx :number
	aiSummary :string
	scriptText :string
}
export const defaultMovOptionVO:QstMovOptionVO = {
	ecIdx: 0,
	aiSummary: "",
	scriptText: "",
}

export interface VideoVO extends HWMap {
	seq :number
	fidx :number
	fileTarget :string
	realfilename :string
	resWidth :number
	resHeight :number
	filesize :number
}




export interface QstPollVO extends HWMap{
	qpIdx :number
	ecIdx :number
	ordering :number
	pollTitle :string
	pollDesc :string
	pollType :string
	contentVO :EduContentVO
	answerList :QstPollAnsVO[]
}
export interface QstPollAnsVO extends HWMap{
	qpaIdx :number
	qpIdx :number
	answer :number
	shortTxt :string
	ordering :number
}
export interface QstPollOptionVO extends HWMap{
	ecIdx :number
	pollTitle :string
	pollDesc :string
	isFile :boolean
	fileList :EduFileVO[]
}




export interface QstReviewVO extends HWMap{
	ecIdx :number
	reviewInfoTxt :string
	isRating :boolean
	isLength :boolean
	minLength :number
}




export interface QstTstVO extends HWMap{
	qtIdx :number
	ecIdx :number
	ordering :number
	cateCode :string
	cateCodeVO :CodeVO
	tstTitle :string
	tstDesc :string
	isImg :boolean
	isAudio :boolean
	isTxt :boolean
	isOX :boolean
	isDrag :boolean
	tstImgSrc :string
	tstAudioSrc :string
	tstType :string
	directCorrect :boolean
	isShuffle :boolean
	incorrectTxt :string
	answerList :TstAnsVO[]
}
export interface QstTstOptionVO extends HWMap{
	ecIdx :number
	testTitle :string
	testNotice :string
	isCutoff :boolean
	cutoffScore :number
	isRandTst :boolean
}
export interface TstAnsVO extends HWMap{
	taIdx :number
	qtIdx :number
	answer :string
	answerImg :string
	answerImgSrc :string
	answerAudioSrc :string
	isCorrect :boolean
	ordering :number
}







export interface EduFileVO extends HWMap{
	fidx :number
	ecIdx :number
	webPath :string
	realfilename :string
	filename :string
	fileext :string
	filesize :number
	isS3 :boolean
	s3RealLink :string
	cntDownload :number
	cntShow :number
	cntPlay :number
	duration :number
	ordering :number
	success :boolean
}

export interface CardFileVO extends HWMap{
	fidx :number
	ecIdx :number
	webPath :string
	realfilename :string
	filename :string
	fileext :string
	filesize :number
	isS3 :boolean
	s3RealLink :string
	cntDownload :number
	cntShow :number
	cntPlay :number
	duration :number
	ordering :number
	success :boolean
}








export interface EduStudentVO extends HWMap{
	sidx:number
	emIdx:number
	userId:string
	
	isRequest:boolean
	isComplete:boolean
	
	checkDate:Date
	requestDate:Date
	completeDate:Date
	
	eduMainVO:EduMainVO
	answerList:EduAnswerVO[]
	CompanyAccountVO:CompanyAccountVO
}








export interface EduAnswerVO extends HWMap{
	eaIdx :number
	emIdx :number
	ecIdx :number
	userId :string
	contentType :string

	isResult :boolean
	isComplete :boolean

	ansMovVO : AnsMovVO
	ansCardVO :AnsCardVO
	ansFileList :AnsFileVO[]
	ansTstList :AnsTstVO[]
	ansPollList :AnsPollVO[]

	fileDownloadCount :number
	tstSolveCount :number
	tstCorrectCount :number
}




export interface AnsCardVO extends HWMap{
	acIdx :number
	eaIdx :number
	userId :string
	eduAnswerVO :EduAnswerVO
}



export interface AnsFileVO extends HWMap{
	afIdx :number
	eaIdx :number
	isDownload :boolean
	userId :string
	fidx :number
	eduAnswerVO :EduAnswerVO
}




export interface AnsMovVO extends HWMap{
	amIdx :number
	eaIdx :number
	currentTimes :number
	completeDate :Date
	userId :string
	eduAnswerVO :EduAnswerVO
}



export interface AnsPollVO extends HWMap{
	apIdx :number
	qpIdx :number
	selTaIdxs :string
	selFidxs :string
	isAnswered :boolean
	eaIdx :number
	userId :string
	shortTxt :string
	eduAnswerVO :EduAnswerVO
	pollAnsList :QstPollAnsVO[]
}


export interface AnsRecordVO extends HWMap{
	seq :number
	ecIdx :number
	userId :string
	recordDate :string
	contentType :string
	mode :string
}



export interface AnsReviewVO extends HWMap{
	arIdx :number
	eaIdx :number
	rating :number
	reviewText :string
	eduAnswerVO :EduAnswerVO
}



export interface AnsTstVO extends HWMap {
	seq :number
	qtIdx :number
	selTaIdxs :string
	eaIdx :number
	userId :string
	shortTxt :string|null
	isCorrect :boolean
	tstAnsList :TstAnsVO[]
}