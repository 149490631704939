import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoading} from "../../../common/redux/slices/publicSlice";

interface LoadingLayerProps {
	isLoading: boolean
}


const LoadingLayer:React.FC = () => {
	
	const dispatch = useDispatch();
	const isLoading = useSelector(selectIsLoading)
	
	/*useEffect(() => {
		console.log( "isLoading", isLoading )
	}, [isLoading])*/
	
	return (
		<>
			{/*로딩 오버레이*/}
			{isLoading && (
				<div className="loading-overlay">
					<img src="/assets/img/LoadingProgress.png" alt="로딩 중" className="loading-spinner" />
				</div>
			)}
		</>
	)
}

export {LoadingLayer}