import {CodeVO} from "../common/code.vo";
import {CompanyAccountVO, CompanyInfoVO} from "../company/company.vo";
import {BoardInfoVO} from "./BoardInfo.vo";
import {DefaultProReqVO, ProReqVO} from "../pro/pro.vo";
import {FileInfoVO} from "../common/common.vo";

export interface BoardVO {
    idx: number;
    pidx: number;
    treeLevel: string;
    depth: number;

    boardKey: string;
    writer: string;
    writerKey: string;
    writerPw: string;
    receiver: string;
    receiverKey: string;
    title: string;
    contentText: string;
    views: number;
    regIp: string;

    isNotice: boolean;
    isSecret: boolean;
    isUse: boolean;

    statusCode: string | null;
    coCode: string;
    jobCode: string;
    skillCode: string;
    cateCode: string | null;

    boardType: string;  // mov, html, card, pdf
    thumbIdx: number | null;
    thumbFile: BoardFileVO | null; // 썸네일 파일

    opt1: string | null;
    opt2: string | null;
    opt3: string | null;
    opt4: string | null;
    opt5: string | null;
    gid: number | null;

    // 임시저장
    isTemp: boolean;

    // @Transient 필드
    contentHtml: string | null;
    // @Transient 필드
    writerInfo: CompanyAccountVO | null;
    writerRate: string | null;

    statusVO: CodeVO | null;
    statusName: string | null;
    cateVO: CodeVO | null;
    cateName: string | null;

    jobList: CodeVO[] | null;
    skillList: CodeVO[] | null;
    attachList: BoardFileVO[] | null;
    commentList: CommentVO[] | null;

    replyCnt: number;
    likeCount: number;
    cautionCount: number;
    commentCount: number;
    reviewCount: number;    // 리뷰 수
    rateAvg: number;    // 별점 평균

    reply: BoardVO | null;
    answer: CommentVO | null;
    isSendMail: boolean;
    isNew: boolean;

    writerDec: string | null;
    writerKeyDec: string | null;
    profileSrc: string | null;
    coInfoVO: CompanyInfoVO | null;
    coName: string | null;
    teamName: string | null;
    boardInfoVO: any | null;


    createDate: Date;
    createUserId: string | null;
    updateDate: Date;
    updateUserId: string | null;

    startDate: string | null;
    startSearchDate: string | null;
    endDate: string | null;
    endSearchDate: string | null;
    status: boolean;
    msg: string;

    tags: string;
    aiHtml: string;
    scriptHtml: string;

    // Best 후기 제목(메인에서 사용)
    bestReviewTitle: string;

    // 임시변수 필드
    duration: number;    // 영상시간
    isWish: boolean; // 책갈피 여부
    isView: boolean; // 시청 여부
    skillName?: string;
    jobName?: string;
    isFile: boolean; // 첨부파일 보유 여부

    // 도움받은 사내고수 정보
    receiverProInfo: ProReqVO
}

export interface BoardFileVO {
    fidx: number; // 자동 생성된 고유 식별자
    bidx?: number; // 게시물 식별자
    webPath: string; // 웹 경로
    realfilename?: string; // 실제 파일 이름
    filename?: string; // 파일 이름
    fileext?: string; // 파일 확장자
    filesize: number; // 파일 크기
    isS3?: boolean; // S3 사용 여부
    s3RealLink?: string; // S3 링크
    fileMode?: string; // 파일 모드 (attach, contents)
    ordering: number; // 정렬 순서
    cntDownload: number; // 다운로드 횟수
    cntShow: number; // 보여준 횟수
    cntPlay: number; // 재생 횟수
    duration: number; // 재생 시간

    fileStatus: string; // 업로드 상태값
    createDate: Date;
    createUserId: string | null;
    updateDate: Date;
    updateUserId: string | null;
    
    fileInfoVO: FileInfoVO;
}

export interface CommentVO {
    cidx: number; // 기본값 0
    pidx: number; // 기본값 0
    treeLevel: string; // 기본값 빈 문자열
    depth?: number; // @Transient, 기본값 0
    bidx?: number; // Long, Null 가능
    writerKey?: string; // 문자열
    writer?: string; // 문자열
    writerPw?: string; // 문자열
    contentText?: string; // @Lob
    contentHtml?: string; // @Transient, 기본값 빈 문자열
    regIp?: string; // IP 주소
    isUse: boolean; // 기본값 true
    opt1?: string; // 옵션 문자열
    opt2?: string; // 옵션 문자열
    opt3?: string; // 옵션 문자열
    rows?: CommentVO[]; // @Transient
    writerKeyDec?: string; // @Transient
    writerDec?: string; // @Transient
    userName?: string; // @Transient, 기본값 빈 문자열
    fileCount?: number; // @Transient, 기본값 0
    writerRate: string | null;

    coName?: string;
    profileSrc?: string;
    me: boolean;
    isTemporary? : boolean
    commentFileList?: CommentFileVO[];

    createDate: Date;
    createUserId: string | null;
    updateDate: Date;
    updateUserId: string | null;
}

export interface CommentFileVO {
    cfidx: number; // 자동 생성된 고유 식별자
    bidx?: number; // 게시물 식별자
    webPath?: string; // 웹 경로
    realfilename?: string; // 실제 파일 이름
    filename?: string; // 파일 이름
    fileext?: string; // 파일 확장자
    filesize: number; // 파일 크기
    isS3?: boolean; // S3 사용 여부
    s3RealLink?: string; // S3 링크
    fileMode?: string; // 파일 모드 (attach, contents)
    ordering: number; // 정렬 순서
    cntDownload: number; // 다운로드 횟수
    cntShow: number; // 보여준 횟수
    cntPlay: number; // 재생 횟수
    duration: number; // 재생 시간

    createDate: Date;
    createUserId: string | null;
    updateDate: Date;
    updateUserId: string | null;
}

export interface CodeCountDto {
    code: string,
    codeName: string,
    count: number
}


export function DefaultBoardVO() {
    const defaultVO:BoardVO = {
        idx: 0,
        pidx: 0,
        treeLevel: "",
        depth: 0,

        boardKey: "",
        writer: "",
        writerKey: "",
        writerPw: "",
        receiver: "",
        receiverKey: "",
        title: "",
        contentText: "",
        views: 0,
        regIp: "",

        isNotice: false,
        isSecret: false,
        isUse: true,
        isWish: false,
        isView: false,

        statusCode: null,
        coCode: "",
        jobCode: "",
        skillCode: "",
        cateCode: null,

        boardType: "",
        thumbIdx: null,
        thumbFile: null,

        opt1: null,
        opt2: null,
        opt3: null,
        opt4: null,
        opt5: null,
        gid: null,

        isTemp: false,

        contentHtml: null,
        writerInfo: null,

        statusVO: null,
        statusName: null,
        cateVO: null,
        cateName: null,

        jobList: null,
        skillList: null,
        attachList: null,
        commentList: null,

        replyCnt: 0,
        likeCount: 0,
        cautionCount: 0,
        commentCount: 0,
        reviewCount: 0,
        rateAvg: 0,

        reply: null,
        answer: null,
        isSendMail: false,
        isNew: false,
        writerRate: "",

        writerDec: null,
        writerKeyDec: null,
        profileSrc: null,
        coInfoVO: null,
        coName: null,
        teamName: null,
        boardInfoVO: null,

        createDate: new Date(),
        createUserId: null,
        updateDate: new Date(),
        updateUserId: null,
        startDate: null,
        startSearchDate: null,
        endDate: null,
        endSearchDate: null,
        status: false,
        msg: "",

        tags: "",
        aiHtml: "",
        scriptHtml: "",

        bestReviewTitle: "",

        duration: 0,
        skillName: "",
        jobName: "",
        isFile: false,

        // 도움받은 사내고수 정보
        receiverProInfo: DefaultProReqVO()
    };

    return defaultVO
}

export function DefaultBoardInfoVO() {
    const defaultVO:BoardInfoVO = {
        boardKey: "",
        boardMode: "",
        boardName: "",
        cateCode: "",
        cateList: [],
        createDate: new Date(),
        createUserId: "",
        description: "",
        endDate: "",
        endSearchDate: "",
        formText: "",
        idx: 0,
        isAnonymous: false,
        isCardContents: false,
        isCategory: false,
        isComment: false,
        isCommentRate: "",
        isCompany: false,
        isDelete: false,
        isFile: false,
        isForm: false,
        isMovContents: false,
        isNickname: false,
        isNologin: false,
        isNotice: false,
        isPersonal: false,
        isPush: false,
        isSecret: false,
        isStatus: false,
        isTarget: false,
        isThumbnail: false,
        isWrite: false,
        isWriteRate: "",
        msg: "",
        pageCount: 0,
        rateCode: "",
        startDate: "",
        startSearchDate: "",
        status: false,
        statusCode: "",
        statusList: [],
        updateDate: new Date(),
        updateUserId: "",
        defaultText: ""

    };

    return defaultVO
}
