import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HWMap} from "../../../core/common/common.vo";



interface PublicData {
	isLoading: boolean,
	params?: HWMap | null
}


const initialState:PublicData = {
	isLoading: false,
	params: null,
}


export const publicSlice = createSlice({
	name: `public`,
	initialState,
	reducers: {
	
		setLoadingLayer: (state, action: PayloadAction<PublicData>) => {
			state.isLoading = action.payload.isLoading
		},
	
	}
})


export const selectIsLoading = (state: { public: PublicData }) => {
	return state.public.isLoading
};


export const {setLoadingLayer} = publicSlice.actions;