import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {HWMap} from "../../../core/common/common.vo";
import screenfull from "screenfull";
import dateUtils from "../../../common/utils/date.utils";
import ToastUtils from "../../../common/utils/toast.utils";
import {OnProgressProps} from "react-player/types/base";
import CommonJS from "../../../common/common";
import {VideoVO} from "../../../core/education/education.vo";
import FileUtils from "../../../common/utils/file.utils";
import FileService from "../../../core/common/file.service";
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;



interface HwsPlayerProps {
	url: string
	playing?: boolean
	ready?: boolean
	pip?: boolean
	controls?: boolean
	width?: string | number
	height?: string | number
	light?: false
	volume?: number
	muted?: false
	played?: number
	loaded?: number
	duration?: number
	playbackRate?: number
	loop?: boolean,
	
	fileTarget?: string,
	thumbnail?: string | boolean,
	isSkip?: boolean,
	isSpeed?: boolean,
	videoList?: Array<VideoVO> | null,
	
	fnSeek?: Function | null,
	fnProgress?: Function | null,
	fnEnded?: Function | null,
}

const HwsPlayer = React.forwardRef( (_props:HwsPlayerProps, ref ) => {

	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)
	
	const [playerProps, setPlayerProps] = useState<HwsPlayerProps>({
		url: `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`,
		pip: false,
		playing: false,
		ready: false,
		controls: false,
		width: _props.width, // 초기 너비 설정
		height: _props.height, // 초기 높이 설정
		light: false,
		volume: 0.8,
		muted: false,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
		
		thumbnail: false,
		isSkip: true,
		isSpeed: true,
		
		fileTarget: "CMS",
		videoList: null,
		fnSeek: null,
		fnProgress: null,
		fnEnded: null,
	});
	
	const $playerRef = useRef<ReactPlayer>(null)
	const $seekingRef = useRef<boolean>(false);
	const continueSeconds = useRef<number>(0);
	
	/**
	 * 초기 effect
	 */
	useEffect(() => {
		
		if ( _props ) {
			
			setPlayerProps(prevState => ({
				...prevState,
				..._props
			}));
			
			setCreated(true)
		}
	
	}, [])
	
	
	/**
	 * created
	 * 추가데이터 조회
	 */
	useEffect(() => {
		
		if ( created ) {
			
			(async () => {
				const realfilename = FileUtils.getRealfilename(playerProps.url);
				const videoList = await FileService.getVideoList(playerProps.fileTarget ?? "CMS", realfilename);
				
				if ( videoList && videoList.length > 0 ) {
					
					// 화질목록 중에 저화질 기본으로
					//const lowVideo = videoList.length > 0 ? videoList[videoList.length - 1] : null;
					
					// 화질목록 중에 고화질 기본으로
					const lowVideo = videoList.length > 0 ? videoList[0] : null;
					
					const lowFilename = lowVideo.realfilename;
					setCurrentResolution(lowVideo.resHeight)
					const lowUrl = FileUtils.getRealfilePath( playerProps.url ) + lowFilename;
					setPlayerProps(prevState => ({
						...prevState,
						url: lowUrl,
						videoList: videoList
					}));
				}
				
				setMounted(true)
			})()
		}
		
	}, [created])
	
	
	/**
	 * mounted
	 * 라이브러리 실행
	 */
	useEffect(() => {
		
		if ( mounted ) {
			CommonJS.hpPlayer()
		}
		
	}, [mounted])
	
	
	/**
	 * 현재 선택된 해상도 값
	 */
	const [currentResolution, setCurrentResolution] = useState<number>()
	const doChangeResolution = async (_index:number) => {
		
		if ( playerProps.videoList ) {
			await setCurrentResolution( playerProps.videoList[_index].resHeight )
			const lowFilename = playerProps.videoList[_index].realfilename;
			const lowUrl = FileUtils.getRealfilePath( playerProps.url ) + lowFilename;
			
			const seconds = $playerRef.current?.getCurrentTime()
			await setPlayerProps(prevState => ({
				...prevState,
				url: lowUrl
			}));
			
			onSeek(seconds)
		}
	}


	
	
	
	const onReady = async () => {
		
		// 준비상태 X + 이어보기 시간 O 있을 경우
		if (!playerProps.ready && continueSeconds.current > 0) {
			console.log("🎧 이어보기 준비 : " + continueSeconds.current);
			await onSeek(continueSeconds.current);
		}
		await setPlayerProps(prev => ({...prev, ready: true}))
	};
	const onPlay = async () => setPlayerProps(prev => ({...prev, playing: true}));
	const onPause = async () => setPlayerProps(prev => ({ ...prev, playing: false }));
	// @ts-ignore
	const onMuted = async () => setPlayerProps(prev => ({...prev, muted: !playerProps.muted}));
	const onProgress = async (state: OnProgressProps) => {
		await setPlayerProps(prev => ({ ...prev, played: state.played, loaded: state.loaded }));
		CommonJS.hpPlayerProgress()
		
		const playseconds = Math.round(state.playedSeconds)
		if ( playerProps.fnProgress != null && typeof playerProps.fnProgress === 'function' && playseconds > 0 ) {
			await playerProps.fnProgress(playseconds)
		}
	};
	const onSeek = async (seconds: any) => {
		//console.log(`seekTo :::::: ${seconds}`)
		if ($playerRef.current) {
			$playerRef.current.seekTo(seconds, 'seconds');
		}
		
		if ( playerProps.fnSeek != null && typeof playerProps.fnSeek === 'function') {
			await playerProps.fnSeek(seconds)
		}
	};
	const onEnded = async () => {
		await setPlayerProps(prev => ({ ...prev, playing: false }))
		if ( playerProps.fnEnded != null && typeof playerProps.fnEnded === 'function') {
			await playerProps.fnEnded()
		}
	};
	const onFullscreen = async () => {
		if ( $playerRef.current ) {
			await screenfull.toggle(document.querySelector('.hp-player')!)
			// screenfull.request($playerRef!.current)
		}
	}
	
	
	
	
	/**
	 * 재생속도 변경 이벤트
	 * @param _speed
	 */
	const onPlaybackRateChange = async (_speed:number) => {
		
		/**
		 * 학습가능상태 확인
		 */
		if ( !playerProps.isSpeed ) {
			ToastUtils.show("빨리듣기가 불가능한 컨텐츠 입니다.")
			$playerRef.current!.getInternalPlayer().playbackRate = 1
		} else {
			$playerRef.current!.getInternalPlayer().playbackRate = _speed
		}
		
		setPlayerProps(prevState => ({
			...prevState,
			playbackRate: $playerRef.current!.getInternalPlayer().playbackRate
		}))
	}
	
	/**
	 * progressBar 마우스 다운
	 * @param e
	 */
	const onSeekMouseDown = (e:any) => {
		//console.log(`onSeekMouseDown :::::: ${e.target.value}`)
		$seekingRef.current = true;
	}
	
	/**
	 * progressBar 변경
	 * @param e
	 */
	const onSeekChange = (e:any) => {
		//console.log(`onSeekChange :::::: ${e.target.value}`)
		setPlayerProps(prev => ({ ...prev, played: e.target.value }));
		onSeek(parseFloat(e.target.value))
		// $playerRef.current?.setState({ played: parseFloat(e.target.value) })
	}
	
	/**
	 * progressBar 마우스 업
	 * @param e
	 */
	const onSeekMouseUp = (e:any) => {
		//console.log(`onSeekMouseUp :::::: ${e.target.value}`)
		$seekingRef.current = false;
	}

	/**
	 * pip toggle
	 */
	const onPipToggle = () => {
		setPlayerProps((prev) => ({...prev, pip: !prev.pip}));
	}
	
	
	/**
	 * 외부 접근 핸들러 정의
	 */
	useImperativeHandle(ref, () => ({
		
		getReady : () => mounted,
		
		/**
		 * Props 반환
		 */
		getProps :() => {
			return playerProps
		},
		
		/**
		 * Props 적용
		 * @param props
		 */
		setProps :(props:HwsPlayerProps) => {
			setPlayerProps(props)
		},
		
		
		seekTo: async (_seconds:number) => {
			continueSeconds.current = _seconds;
			console.log("🎧 이어보기 : " + _seconds);
			await onSeek(_seconds);
		},
		
		
		getInternalPlayer: () => (
			$playerRef.current?.getInternalPlayer()
		)
		
	}))

	/**
	 * 마우스 우클릭 방지
	 * @param event
	 * onContextMenu 우클릭 이벤트 핸들러 연결
	 */
	const preventContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();
	};
	
	
	return (
		<div className="hp-player type-hover" onContextMenu={preventContextMenu} style={{ width: playerProps.width, height: playerProps.height }}>
			<div className="hp-video" style={{backgroundColor: `black`}}>
				<ReactPlayer className={`playerRef`}
				             ref={$playerRef}
				             url={playerProps.url}
				             width={playerProps.width}
				             height={playerProps.height}
				             style={{ objectFit: 'contain' }} // contain 사용
				             light={playerProps.thumbnail ? <img src={`${playerProps.thumbnail}`} alt='Thumbnail' style={{zIndex: 50}} onClick={onPlay} /> : false}
				             playIcon={<button style={{zIndex: 55, position: `absolute`}} onClick={onPlay}><img src={`/assets/img/play_icon.png`} style={{width: "80px"}} /></button>}
				             playing={playerProps.playing}
				             muted={playerProps.muted}
				             onReady={onReady}
					         onPlay={onPlay}
					         onPause={onPause}
					         onProgress={onProgress}
					         //onSeek={onSeek}
					         onPlaybackRateChange={() => {}}
				             onEnded={onEnded}
							 pip={playerProps.pip}
				/>
			</div>
			<div className="hp-control-bar">
				<div className="hp-progress-bar">
					<input type="range"
					       className="inp-range"
					       min={0}
					       max={$playerRef.current?.getDuration()}
					       step={0.0001}
					       value={$playerRef.current?.getCurrentTime()}
					       onMouseDown={onSeekMouseDown}
					       onChange={onSeekChange}
					       onMouseUp={onSeekMouseUp}
					       readOnly={playerProps.isSkip} />
				</div>
				<div className="hp-control-button">
					<div className="hp-play-button">
						{ playerProps?.playing || <button type="button" className="btn-play" onClick={onPlay}><span className="blind">재생</span></button> }
						{ playerProps?.playing && <button type="button" className="btn-pause" onClick={onPause}><span className="blind">일시정지</span></button> }
						<div className="hp-timeclip">
							<span>{ $playerRef.current && dateUtils.formatTime( $playerRef.current.getCurrentTime() ) }</span>
							/
							<span>{ $playerRef.current && dateUtils.formatTime( $playerRef.current.getDuration()) }</span>
						</div>
					</div>
					<div className="hp-util-button">
						{ playerProps.muted || <button type="button" className="btn-volume" onClick={onMuted}><span className="blind">음소거 해제</span></button> }
						{ playerProps.muted && <button type="button" className="btn-mute" onClick={onMuted}><span className="blind">음소거</span></button> }
						
						{
							playerProps.isSpeed &&
							<div className="hp-dropdown">
								<button type="button" className="btn-dropdown btn-speed"><span className="blind">재생속도</span></button>
								<ul className="dropdown-list">
									<li><button type="button" onClick={() => onPlaybackRateChange(1)} className={playerProps.playbackRate === 1 ? "active" : ""}>1x</button></li>
									<li><button type="button" onClick={() => onPlaybackRateChange(1.25)} className={playerProps.playbackRate === 1.25 ? "active" : ""}>1.25x</button></li>
									<li><button type="button" onClick={() => onPlaybackRateChange(1.5)} className={playerProps.playbackRate === 1.5 ? "active" : ""}>1.5x</button></li>
									<li><button type="button" onClick={() => onPlaybackRateChange(2)} className={playerProps.playbackRate === 2 ? "active" : ""}>2x</button></li>
								</ul>
							</div>
						}
						
						{
							playerProps.videoList &&
							<div className="hp-dropdown">
								<button type="button" className="btn-dropdown btn-quality"><span className="blind">화질</span></button>
								<ul className="dropdown-list">
									{ playerProps.videoList.map((video, v_index) => (
										<li key={video.seq}><button type="button" className={video.resHeight === currentResolution ? `active` : ``} onClick={() => doChangeResolution(v_index)}>{video.resHeight}p</button></li>
									)) }
								</ul>
							</div>
						}
						<button type="button" className="btn-pip" onClick={onPipToggle}><span className="blind">작은화면</span></button>
						<button type="button" className="btn-fullscreen" onClick={() => onFullscreen()}><span className="blind">전체화면</span></button>
					</div>
				</div>
			</div>
		</div>
	)
})

HwsPlayer.displayName = `hwsPlayer`
export default HwsPlayer