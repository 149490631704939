


export interface HWMap {
	[key:string] :any
}




export interface FileVO {
	fidx: number; // 자동 생성된 고유 식별자
	bidx?: number; // 게시물 식별자
	webPath: string; // 웹 경로
	realfilename?: string; // 실제 파일 이름
	filename?: string; // 파일 이름
	fileext?: string; // 파일 확장자
	filesize: number; // 파일 크기
	isS3?: boolean; // S3 사용 여부
	s3RealLink?: string; // S3 링크
	fileMode?: string; // 파일 모드 (attach, contents)
	ordering: number; // 정렬 순서
	cntDownload: number; // 다운로드 횟수
	cntShow: number; // 보여준 횟수
	cntPlay: number; // 재생 횟수
	duration: number; // 재생 시간
	
	fileStatus: string; // 업로드 상태값
	createDate: Date;
	createUserId: string | null;
	updateDate: Date;
	updateUserId: string | null;
	
	fileInfoVO: FileInfoVO;
}




export interface FileInfoVO {
	seq: number,
	fileStatus: string,
	fileTarget: string,
	fidx: number,
	descriptionOrigin: string,
	description: string,
	summary: string,
	dagloTransRequest: string,
	dagloTransRequestResult: string,
	dagloTransResponse: string,
	openaiReadableRequest: string,
	openaiReadableResponse: string,
	openaiSummarizeRequest: string,
	openaiSummarizeResponse: string,
}
export const defaultFileInfoVO:FileInfoVO = {
	seq: 0,
	fileStatus: "",
	fileTarget: "",
	fidx: 0,
	descriptionOrigin: "",
	description: "",
	summary: "",
	dagloTransRequest: "",
	dagloTransRequestResult: "",
	dagloTransResponse: "",
	openaiReadableRequest: "",
	openaiReadableResponse: "",
	openaiSummarizeRequest: "",
	openaiSummarizeResponse: "",
}