import React, {useEffect} from "react";
import {BoardFileVO, BoardVO} from "../../../core/board/board.vo";
import HwsPlayer from "../common/HwsPlayer";
import FileUtils from "../../../common/utils/file.utils";

const BoardMov : React.FC<{fileList?: BoardFileVO[]; boardVO: BoardVO}> = ({fileList, boardVO}) => {

    const [videoFile, setVideoFile] = React.useState<BoardFileVO>();

    useEffect(() => {
        fileList?.map((item: BoardFileVO, index: number) => {
            if ("mov" === item.fileMode) {
                return setVideoFile(item);
            }
        });
    }, [fileList]);

    return (
        <div className="detail-top">
            {
                videoFile?.s3RealLink
                    ? <div className="media-content">
                        <HwsPlayer key={FileUtils.getImageSrc(videoFile) || ""} url={FileUtils.getImageSrc(videoFile) || ""} thumbnail={FileUtils.getImageSrc(boardVO.thumbFile)} fileTarget={`BOARD`} width={`100%`} height={`100%`} />
                      </div>
                    : <div className="media-content" style={{height:'50px', textAlign: 'center'}}> <h4>영상이 존재하지 않습니다.</h4> </div>
            }
        </div>
    );
}

export default BoardMov;